import React, { useEffect, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import OCSignIn from "./components/OCSignIn";
import { themeVars } from "./themes/oc-dark-default";
import {
  ThemeProvider,
  Theme,
  defaultDarkModeOverride,
  Authenticator,
  Link,
  Image,
} from "@aws-amplify/ui-react";
import {
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Auth } from "aws-amplify";

interface IHeaderProps {
  title: string | JSX.Element;
  tokens?: any;
  children?: JSX.Element;
}

function OCHeader(props: IHeaderProps): JSX.Element {
  const { title, children } = props;
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        xs={12}
        item
        sx={{
          textAlign: "center",
          height: 80,
        }}
      >
        <Image
          alt="Vmo Solutions"
          src="public/images/VMOLogo.svg"
          objectFit="initial"
          backgroundColor="initial"
          height="100%"
          opacity="100%"
        />
      </Grid>
      <Grid>
        <Typography
          component="h3"
          sx={{ pt: 2, fontWeight: 700, color: "#FFF", fontSize: "2em" }}
        >
          {title}
        </Typography>
      </Grid>
      {children && (
        <Grid container item sx={{ pt: 2 }}>
          {children}
        </Grid>
      )}
    </Grid>
  );
}
function VMOTitle(): JSX.Element {
  return (
    <Box sx={{ textAlign: "center" }}>
      Welcome to{" "}
      <Image
        alt="OC"
        src="public/images/OCLogo.svg"
        objectFit="initial"
        objectPosition="50% 50%"
        backgroundColor="initial"
        height="13%"
        width="13%"
        opacity="100%"
      />
    </Box>
  );
}
function OCAuth(props: any): JSX.Element {
  const { config } = props;
  const formFields = {
    confirmVerifyUser: {
      confirmation_code: {
        label: "Confirmation Code",
        placeholder: "Enter your Confirmation Code:",
        isRequired: false,
      },
    },
  };

  const redirectURL = (): string => {
    if (process.env.NODE_ENV === "development") {
      return "/app#";
    } else {
      return config.Auth.oauth.redirectSignIn;
    }
  };

  return (
    <Authenticator hideSignUp={true} formFields={formFields}>
      {({ signOut, user }) => (
        <Grid item sx={{ textAlign: "center" }}>
          {process.env.NODE_ENV !== "development" && (
            <>
              Redirecting...{" "}
              <Link to={redirectURL()}>
                (click here if you are not redirected)
              </Link>
              <OCSignIn redirectURL={redirectURL()} />
            </>
          )}
          {process.env.NODE_ENV === "development" && (
            <>
              This is a development environment. The login succeeded, and your
              OC instance should be ready.
              <br />
              <br />
              <Button
                onClick={() => {
                  Auth.signOut();
                }}
              >
                Sign Out
              </Button>
            </>
          )}
        </Grid>
      )}
    </Authenticator>
  );
}

function App(): JSX.Element {
  const [config, setConfig] = useState<any>(null);
  useEffect(() => {
    const cnf = JSON.parse(localStorage.getItem("amplify_config"));
    setConfig(cnf);
  }, []);
  const AWSTheme: Theme = {
    name: "AWSTheme",
    overrides: [
      defaultDarkModeOverride,
      {
        colorMode: "dark",
        tokens: {
          components: {
            button: {
              primary: {
                backgroundColor: themeVars.custom.forms.inputBack,
                color: themeVars.custom.forms.buttonText,
              },
            },
          },
        },
      },
    ],
  };
  const SSOSignIn = (): void => {
    const clientId = config.Auth.userPoolWebClientId;
    const url =
      "https://" +
      config.Auth.oauth.domain +
      "/login?redirect_uri=" +
      config.Auth.oauth.redirectSignIn +
      "&response_type=" +
      config.Auth.oauth.responseType +
      "&client_id=" +
      clientId;
    window.location.assign(url);
  };
  return (
    <ThemeProvider theme={AWSTheme} colorMode="dark">
      <Grid container justifyContent="center" sx={{ paddingTop: "40px" }}>
          <OCHeader title={<VMOTitle />}>
            {process.env.REACT_APP_SSO_ENABLED === "true" && (
              <Grid item sx={{ p: 2 }} xs={12}>
                <Box sx={{ textAlign: "center" }}>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => SSOSignIn()}
                  >
                    Click here to sign in with your Corporate ID
                  </Button>
                </Box>
              </Grid>
            )}
          </OCHeader>
          {process.env.REACT_APP_SSO_ENABLED === "true" && (
            <Accordion sx={{ background: "#0D1A26" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box>
                  <Typography>
                    Or sign in with your internal ID below.
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {config && <OCAuth config={config} />}
              </AccordionDetails>
            </Accordion>
          )}
          {process.env.REACT_APP_SSO_ENABLED !== "true" && config && (
            <OCAuth config={config} />
          )}
      </Grid>
    </ThemeProvider>
  );
}
export default App;
